import { TaskResponse } from "@9amhealth/openapi";
import { Button } from "@9amhealth/shared";
import styled from "@emotion/styled";
import { FC, default as React, ReactNode } from "react";
import {
  IconForkAndKnifePurple,
  IconPrecisionPurple,
  IconRxDietLogoPurple,
  IconShoppingCartPurple
} from "src/constants/icons";
import { APP_BREAKPOINT } from "src/constants/layout";
import translate from "src/lib/translate";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import TaskManagementBloc, {
  TaskKey
} from "src/state/TaskManagementBloc/TaskManagementBloc";
import { useBloc } from "src/state/state";
import rxDietCardBgImgDesktop from "src/ui/assets/images/rxdiet-card-bg-desktop.jpg";
import rxDietCardBgImgMobile from "src/ui/assets/images/rxdiet-card-bg-mobile.jpg";
import rxDietCardTaskCompletedBgImg from "src/ui/assets/images/rxdiet-card-task-completed-bg.jpg";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";
import Translate from "src/ui/components/Translate/Translate";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";

//#region Styled Components
const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1.5rem 1rem 1rem;
  color: var(--color-charcoal);
  border-radius: 0.5rem;
  background-image: url(${rxDietCardBgImgMobile});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: var(--light-shadow);
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    gap: 1.5rem;
    padding: 2rem 1.5rem 1.5rem;
    background-image: url(${rxDietCardBgImgDesktop});
  }
`;

const StyledCardTaskCompleted = styled(StyledCard)`
  position: relative;
  overflow: hidden;
  background-image: url(${rxDietCardTaskCompletedBgImg});

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    background-image: url(${rxDietCardTaskCompletedBgImg});
  }
`;

const StyledAbsoluteImg = styled.img`
  display: block;
  position: absolute;
  top: -2rem;
  right: -3rem;
  width: 11.25rem;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow:
    -13px 11px 17px 0px rgba(98, 53, 204, 0.06),
    -3px 3px 9px 0px rgba(33, 33, 33, 0.2);
`;

const StyledList = styled.ul`
  margin: 0;
`;

const StyledListItem = styled.li`
  margin: 0 0 0.625rem 0;
  padding: 0;
  display: flex;
  column-gap: 0.5rem;

  &::before {
    display: none;
  }
`;

export const StyledPill = styled.div`
  color: var(--Partners-RxDiet-Primary, #6235cc);
  border-radius: 7.5rem;
  background: rgba(98, 53, 204, 0.08);
  backdrop-filter: blur(0.5rem);
  width: fit-content;
  padding: 0.125rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.21875rem;
`;

export const StyledButton = styled(Button)`
  color: #fff;
  --btn-background: var(--Partners-RxDiet-Primary, #6235cc) !important;
  --inner-opacity: 0 !important;
  --focus-inner-opacity: 0.2 !important;

  &.description-dialog-button {
    width: fit-content;
    margin: 1rem auto 0;

    @media screen and (min-width: ${APP_BREAKPOINT}px) {
      margin: 0;
    }
  }
`;

const ImgCarousel = styled.div`
  display: flex;
  gap: 0.75rem;
  padding: 0 0 2rem 1rem;
  margin: 0 -1rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    padding: 0 0 2.25rem 1.5rem;
    margin: 0 -1.5rem;
  }

  // Scrollbar
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  img {
    width: 5rem;
    aspect-ratio: 1;
    position: relative;
    border-radius: 50%;
    box-shadow:
      -13px 11px 17px 0px rgba(98, 53, 204, 0.06),
      -3px 3px 9px 0px rgba(33, 33, 33, 0.2);
  }
`;
//#endregion

interface RxDietCardData {
  card: {
    sectionTitle?: string | ReactNode;
    pillText?: string | ReactNode;
    pillImg?: string | ReactNode;
    title?: string | ReactNode;
    subtitle?: string | ReactNode;
    listItems?: {
      icon: string | ReactNode;
      text: string | ReactNode;
    }[];
    sliderImgs?: {
      src: string;
      alt?: string;
    }[];
    buttonText: string | ReactNode;
  };
  cardTaskCompleted: {
    title?: string | ReactNode;
    subtitle?: string | ReactNode;
    img?: {
      src: string;
      alt?: string;
    };
  };
}

interface RxDietCardProps {
  data?: RxDietCardData;
}

const defaultRxDietCardData: RxDietCardData = {
  card: {
    sectionTitle: <Translate msg="nutritionProgram" />,
    pillText: <Translate msg="poweredBy" />,
    pillImg: <IconRxDietLogoPurple />,
    title: <Translate msg="rxDiet.card.title" />,
    subtitle: <Translate msg="rxDiet.card.subtitle" />,
    listItems: [
      {
        icon: <IconPrecisionPurple />,
        text: <Translate msg="rxDiet.card.listItem_nutrition" />
      },
      {
        icon: <IconForkAndKnifePurple />,
        text: <Translate msg="rxDiet.card.listItem_preferences" />
      },
      {
        icon: <IconShoppingCartPurple />,
        text: <Translate msg="rxDiet.card.listItem_delivery" />
      }
    ],
    sliderImgs: [
      {
        src: "https://cdn.sanity.io/images/g38rxyoc/production/2809224f21d8f29d3d4d5bb76f881c425397cdf2-200x200.jpg",
        alt: translate("rxDiet.img.mealPlate.alt")
      },
      {
        src: "https://cdn.sanity.io/images/g38rxyoc/production/72cfb44d1a11287e995757204af9d94c666137ed-200x200.jpg",
        alt: translate("rxDiet.img.mealPlate.alt")
      },
      {
        src: "https://cdn.sanity.io/images/g38rxyoc/production/9eef9ff2b477ebb014fad6cfa08399dcd4bba32a-204x204.jpg",
        alt: translate("rxDiet.img.mealPlate.alt")
      },
      {
        src: "https://cdn.sanity.io/images/g38rxyoc/production/8cc09ce497866932b262461b846517e0d36a9a53-200x200.jpg",
        alt: translate("rxDiet.img.mealPlate.alt")
      },
      {
        src: "https://cdn.sanity.io/images/g38rxyoc/production/fabbae4da087a6159c99fdc8379395ea5be4ec8a-200x200.jpg",
        alt: translate("rxDiet.img.mealPlate.alt")
      },
      {
        src: "https://cdn.sanity.io/images/g38rxyoc/production/a26e43461b4b2a8c09728a9f64350afe1ad94e85-200x200.jpg",
        alt: translate("rxDiet.img.mealPlate.alt")
      },
      {
        src: "https://cdn.sanity.io/images/g38rxyoc/production/148736822ae36d094919d714e60547cf772e8748-200x200.jpg",
        alt: translate("rxDiet.img.mealPlate.alt")
      },
      {
        src: "https://cdn.sanity.io/images/g38rxyoc/production/3e4f9394d8d339a5591916b9fdec21b5a832ab53-200x200.jpg",
        alt: translate("rxDiet.img.mealPlate.alt")
      }
    ],
    buttonText: <Translate msg="rxDiet.button.createFirstMealPlan" />
  },
  cardTaskCompleted: {
    title: <Translate msg="mealPlan" />,
    subtitle: <Translate msg="rxDiet.achieveYourGoals" />,
    img: {
      src: "https://cdn.sanity.io/images/g38rxyoc/production/2809224f21d8f29d3d4d5bb76f881c425397cdf2-200x200.jpg",
      alt: translate("rxDiet.img.mealPlate.alt")
    }
  }
};

const RxDietCard: FC<RxDietCardProps> = ({ data = defaultRxDietCardData }) => {
  const { card, cardTaskCompleted } = data;

  const [, { getProgramTasks }] = useBloc(TaskManagementBloc);

  const [createFirstMealPlanTaskStatus, setCreateFirstMealPlanTaskStatus] =
    React.useState<TaskResponse.status>(TaskResponse.status.LOCKED);

  const mealPlanTasks = getProgramTasks(KnownProgram.MEAL_PLAN);

  React.useEffect(() => {
    if (mealPlanTasks.length) {
      const createFirstMealPlanTask = mealPlanTasks.find(
        (task) =>
          `${task.program}.${task.group}.${task.slug}` ===
          TaskKey.RXDIET_MEAL_PLAN_SETUP
      );

      if (createFirstMealPlanTask) {
        setCreateFirstMealPlanTaskStatus(createFirstMealPlanTask.status);
      }
    }
  }, [mealPlanTasks]);

  return (
    <>
      {createFirstMealPlanTaskStatus !== TaskResponse.status.LOCKED &&
        createFirstMealPlanTaskStatus !== TaskResponse.status.SKIPPED && (
          <nine-info-section>
            <div slot="content">
              {card.sectionTitle && (
                <CollectionTitle>{card.sectionTitle}</CollectionTitle>
              )}

              {createFirstMealPlanTaskStatus ===
                TaskResponse.status.COMPLETED && (
                <StyledCardTaskCompleted
                  onClick={() => {
                    AppQueryPopupsController.openPopup(
                      AppPopup.rxDietNutritionProgram
                    );
                  }}
                >
                  {card.pillText && (
                    <StyledPill>
                      <small className="as-tiny">{card.pillText}</small>
                      {card.pillImg}
                    </StyledPill>
                  )}

                  {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
                  {(cardTaskCompleted.title || cardTaskCompleted.subtitle) && (
                    <div style={{ width: "60%" }}>
                      {cardTaskCompleted.title && (
                        <>
                          <h4 className="as-h4-large">
                            {cardTaskCompleted.title}
                          </h4>
                          <nine-spacer s="xxs" />
                        </>
                      )}

                      {cardTaskCompleted.subtitle && (
                        <>
                          <p className="m0 color-c-80">
                            {cardTaskCompleted.subtitle}
                          </p>
                          <nine-spacer s="sm" />
                        </>
                      )}
                    </div>
                  )}

                  {cardTaskCompleted.img && (
                    <StyledAbsoluteImg
                      src={cardTaskCompleted.img.src}
                      alt={cardTaskCompleted.img.alt}
                    />
                  )}
                </StyledCardTaskCompleted>
              )}

              {(createFirstMealPlanTaskStatus ===
                TaskResponse.status.AVAILABLE ||
                createFirstMealPlanTaskStatus ===
                  TaskResponse.status.IN_PROGRESS) && (
                <StyledCard
                  onClick={() => {
                    AppQueryPopupsController.openPopup(
                      AppPopup.rxDietNutritionProgram,
                      {
                        additionalParameters: {
                          showFirstStep: `${createFirstMealPlanTaskStatus === TaskResponse.status.AVAILABLE}`
                        }
                      }
                    );
                  }}
                >
                  {card.pillText && (
                    <StyledPill>
                      <small className="as-tiny">{card.pillText}</small>
                      {card.pillImg}
                    </StyledPill>
                  )}

                  {/* eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing */}
                  {(card.title || card.subtitle) && (
                    <div>
                      {card.title && (
                        <>
                          <h4 className="as-h4-large">{card.title}</h4>
                          <nine-spacer s="xxs" />
                        </>
                      )}
                      {card.subtitle && <p className="m0">{card.subtitle}</p>}
                    </div>
                  )}

                  {Boolean(card.listItems?.length) && (
                    <StyledList>
                      {card.listItems?.map((listItem, i) => (
                        <StyledListItem key={`rxdiet-list-item-${i}`}>
                          <div>{listItem.icon}</div>
                          <small>{listItem.text}</small>
                        </StyledListItem>
                      ))}
                    </StyledList>
                  )}

                  <div>
                    {Boolean(card.sliderImgs?.length) && (
                      <ImgCarousel>
                        {card.sliderImgs?.map((img, i) => (
                          <img
                            key={`rxdiet-slider-img-${i}`}
                            src={img.src}
                            alt={img.alt}
                          />
                        ))}
                      </ImgCarousel>
                    )}

                    {!card.sliderImgs?.length && <nine-spacer s="xs" />}

                    <StyledButton
                      center
                      onPress={() => {
                        AppQueryPopupsController.openPopup(
                          AppPopup.rxDietNutritionProgram,
                          {
                            additionalParameters: {
                              showFirstStep: `${createFirstMealPlanTaskStatus === TaskResponse.status.AVAILABLE}`
                            }
                          }
                        );
                      }}
                      style={{ width: "100%", boxShadow: "none" }}
                    >
                      {createFirstMealPlanTaskStatus ===
                      TaskResponse.status.AVAILABLE ? (
                        card.buttonText
                      ) : (
                        <Translate msg="continue" />
                      )}
                    </StyledButton>
                  </div>
                </StyledCard>
              )}
            </div>
          </nine-info-section>
        )}
    </>
  );
};

export default RxDietCard;
